import { initialVisibilityToggles, type VisibilityToggles } from '@/planner/visibilityToggles';
import type { Vector3 } from 'three';
import { zero3 } from '@/geometry/vector3';
import type { ComponentCatalog } from '@/formus/catalog/catalog';
import type { ApiFittedComponents } from '@/api/fittedComponents/getFittedComponents';
import type { BodySide } from '@/formus/anatomy/side';
import type { Url } from '@/formus/types';
import { type LpsVectors } from '@/formus/anatomy/LPS';
import type { ManualTemplateState } from '@/planner/template/manualTemplateState';
import type { AxisMeasurement } from '@/formus/anatomy/measurements';
import type { NumberArray3 } from '@/geometry/apiVector';
import type { AlignmentMode } from '@/formus/anatomy/pelvis/alignment';
import type { SpinoPelvicLandmarks } from '@/components/spinopelvic/calculations';
import type { CatstacksData } from '@/planner/cat-stack/catstacksData';
import type { CatstacksState } from '@/planner/cat-stack/catstacksState';
import type { StemSystem } from '@/formus/catalog/stem';
import type { PlannerOperationId } from '@/planner/executeOperation';
import type { Template } from '@/formus/template/template';

/**
 * Different modes for rendering the objects in the 3D scene
 *
 * - 'normal': Standard shaded and lit appearance
 * - 'xray': Surfaces are transparent dependent on the angle they are viewed, becoming more opaque
 *   as they become tangential to the view direction.
 */
export type RenderingMode = 'normal' | 'xray';

export const plannerModes = ['default', 'cup', 'stem', 'combined', 'disabled'] as const;

export type PlannerMode = (typeof plannerModes)[number];

export type CaseInfo = {
    caseId: number;
    caseUrl: Url;
    caseVersion: string;
    operationalSide: BodySide;
    autoTemplate: Template;
    alignmentMode: AlignmentMode;
    manualTemplateUrl: Url;
    manualTemplateCanonicalUrl: Url;
    manualTemplateLastApprovedUrl: Url | null;
    preferredStemSystem: StemSystem | null;

    /**
     * The targets specified at the time of creating the case.
     * These values are used to set the targetAdjustments until the user overrides the targets,
     * in the optimize-target modal, given the template targets are null when created.
     */
    targets: Adjustments;
};

/** Optimisation targets */
export type Adjustments = {
    /** The change in leg-length */
    legLength: number;

    /** The change in offset */
    offset: number;
};

/** Native measurements to be displayed in the overlay view */
export type NativeMeasurements = {
    hipLengthDifference: number | null;
    femoralAnteversion: number | null;
    acetabularAnteversion: number | null;
    acetabularInclination: number | null;
};

export type FemoralFeatures = {
    shaftAxis: AxisMeasurement;
    proximalShaftAxis: AxisMeasurement;
    neckAxis: AxisMeasurement;
    anteversionNeck: NumberArray3;
    anteversionCondylar: NumberArray3;
    stemTranslationBasis: LpsVectors;
    shaftBasis: LpsVectors;
};

export type PlannerState = {
    // View state
    plannerMode: PlannerMode;
    renderingMode: RenderingMode;
    currentOperation: PlannerOperationId | null;
    visibility: VisibilityToggles;
    catstacks: CatstacksState;

    // Loaded state
    case: CaseInfo | null;
    sceneCentre: Vector3;
    autoTemplate: Template | null;
    template: ManualTemplateState | null;

    /**
     * The target adjustments that are currently being displayed in the planner.
     * These are used for every calculation in the planner (e.g.: warning adjustments)
     * These are defaulted in the following order:
     * - the template targets
     * - the specification targets
     * - the preferences targets
     *
     * Note: that the targets in the template can be null (as it is usually the case
     * when the manual template is created for the first time).
     */
    targetAdjustments: Adjustments | null;
    catstacksData: CatstacksData | null;
    nativeMeasurements: NativeMeasurements;
    hipSpineLandmarks: SpinoPelvicLandmarks | null;
    femoralFeatures: FemoralFeatures | null;
    catalog: ComponentCatalog | null;
    fittedComponents: ApiFittedComponents | null;
};

/**
 * Create the initial planner-state
 */
export function plannerState(): PlannerState {
    return {
        plannerMode: 'disabled',
        renderingMode: 'normal',
        currentOperation: null,
        visibility: initialVisibilityToggles('default'),
        catstacks: {
            visible: false,
            axial: { min: 0, max: 0, sliceIndex: 0 },
            coronal: { min: 0, max: 0, sliceIndex: 0 },
        },

        case: null,
        autoTemplate: null,
        template: null,
        targetAdjustments: null,
        catstacksData: null,
        nativeMeasurements: {
            hipLengthDifference: null,
            femoralAnteversion: null,
            acetabularAnteversion: null,
            acetabularInclination: null,
        },
        hipSpineLandmarks: null,
        femoralFeatures: null,
        sceneCentre: zero3(),
        catalog: null,
        fittedComponents: null,
    };
}

export function resetPlannerState(state: PlannerState) {
    // Assign all properties from the initial state except those in NO_RESET_PROPERTIES
    const NO_RESET_PROPERTIES = ['catalog'];
    const resetProperties = Object.fromEntries(
        Object.entries(plannerState()).filter(([name]) => !NO_RESET_PROPERTIES.includes(name)),
    );
    Object.assign(state, resetProperties);
}
