<template>
    <planner-overlay style="position: relative; height: 100%; width: 100%">
        <planner-viewport />
    </planner-overlay>
</template>

<script setup lang="ts">
    import { useRoute } from 'vue-router';
    import PlannerViewport from '@/planner/PlannerViewport.vue';
    import PlannerOverlay from '@/planner/PlannerOverlay.vue';
    import { usePlannerStore } from '@/planner/plannerStore';
    import { watchImmediate } from '@vueuse/core';
    import { useVersion } from '@/stores/version';

    const route = useRoute();
    const plannerStore = usePlannerStore();

    const versionStore = useVersion();
    await versionStore.load();

    watchImmediate(
        () => route.params.id,
        () => plannerStore.loadCase(Number(route.params.id)),
    );
</script>
