<template>
    <div>
        <optimisation-targets />
        <app-divider />
        <stem-selection />
        <app-divider />
        <stem-transform />
        <div class="actions">
            <app-button
                colour="blue-button"
                @click="planner.resetStem()"
                :disabled="isResetStemDisabled"
                >Reset</app-button
            >
        </div>
    </div>
</template>

<script setup lang="ts">
    import StemSelection from '@/planner/components/StemSelection.vue';
    import StemTransform from '@/planner/components/StemTransform.vue';
    import OptimisationTargets from '@/planner/components/OptimisationTargets.vue';
    import { usePlannerStore } from '@/planner/plannerStore';
    import { computed } from 'vue';
    import { templateUpdate } from '@/planner/template/templateSyncStore';
    import {
        formatTemplateStemDifferences,
        templateStemPropertiesAreEqual,
    } from '@/planner/template/templateComparison';
    import { taggedLogger } from '@/util';

    const log = taggedLogger('stem-panel');

    const planner = usePlannerStore();

    const isResetStemDisabled = computed(() => {
        if (!planner.case?.autoTemplate || !planner.template) {
            return true;
        }
        const autoTemplate = planner.case.autoTemplate;
        const current = templateUpdate(planner.template);
        if (templateStemPropertiesAreEqual(current, autoTemplate)) {
            return true;
        } else {
            const error = [
                'Stem changes to automated surgical template are:',
                ...formatTemplateStemDifferences(current, autoTemplate),
            ].join('\n  ');
            log.debug(`Reset enabled: ${error}`);
            return false;
        }
    });
</script>

<style scoped>
    .actions {
        display: flex;
        width: 100%;
        justify-content: end;
        margin-top: 20px;
    }
</style>
