import { taggedLogger, assert } from '@/util';
import { useAppErrorStore } from '@/stores/appErrorStore';
import { type Ref } from 'vue';

const log = taggedLogger('execute-operation');

/** Blocking planner operations, during which we disable the UI to allow the operation to complete */
export type PlannerOperationId =
    | 'load-case'
    | 'set-targets'
    | 'reset-cup'
    | 'reset-stem'
    | 'approve-plan';

export type PlannerOperationFn = (signal: AbortSignal) => Promise<void>;

export type PlannerOperationExecutor = {
    /**
     * Execute some blocking 'operation'. This should be invoked directly from a UI interaction, and
     * will block other operations until the operation is complete.
     *
     * @param operationId an identifier for the operation
     * @param operation the action to execute
     */
    executeOperation: (
        operationId: PlannerOperationId,
        operation: PlannerOperationFn,
    ) => Promise<void>;

    /**
     * Abort the current operation, if there is one
     */
    abortOperation: () => void;
};

export function plannerOperationExecutor(
    currentOperation: Ref<PlannerOperationId | null>,
): PlannerOperationExecutor {
    let controller: AbortController | null = null;

    return {
        executeOperation: async (operationId: PlannerOperationId, operation: PlannerOperationFn) => {
            // Check that we are not in an error state
            if (useAppErrorStore().hasError) {
                log.error("Ignoring operation '%s' owing to error", operationId);
                return;
            }

            // Check that we are not already in an operation
            const currentOp = currentOperation.value;
            if (currentOp !== null) {
                useAppErrorStore().handleError(
                    Error(`Cannot execute operation '${operationId}': already executing '${currentOp}'`)
                )
                return;
            }

            // Create a new abort-controller for this execution
            assert(!controller, `Abort-controller non-null when executing ${operationId}`);
            controller = new AbortController();
            const signal = controller.signal;

            currentOperation.value = operationId;

            try {
                await operation(signal);
            } catch (error) {
                if (signal?.aborted) {
                    log.info("Aborted operation '%s'", operationId);
                } else {
                    useAppErrorStore().handleError(error);
                }
            }

            controller = null;
            assert(
                currentOperation.value === operationId,
                `Current operation changed to ${currentOperation.value} during execution of ${operationId}`,
            );
            currentOperation.value = null;
        },
        abortOperation: () => {
            if (controller) {
                controller.abort();
            }
        },
    };
}
