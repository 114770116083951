<template>
    <div class="app-optimisation-targets">
        <div class="app-optimisation-target-content">
            <group-label>Optimization Targets</group-label>
            <div class="app-optimisation-target">
                Leg length: {{ formatLength(store.targetAdjustments?.legLength) }}
            </div>
            <div class="app-optimisation-target">
                Offset: {{ formatLength(store.targetAdjustments?.offset) }}
            </div>
        </div>
        <v-btn
            class="app-optimisation-target-dialog"
            icon="mdi-pencil"
            size="x-small"
            :disabled="editDisabled"
            @click="openDialog"
        />
        <v-dialog v-model="isDialogOpen" width="600" persistent>
            <v-card>
                <app-modal-title> Edit Targets</app-modal-title>

                <app-modal-content>
                    <div>
                        <group-label>Leg Length (mm)</group-label>
                        <planner-slider v-model="selectedAdjustments.legLength" :min=-20 :max=20 />
                    </div>
                    <div>
                        <group-label>Offset (mm)</group-label>
                        <planner-slider v-model="selectedAdjustments.offset" :min=-20 :max="20" />
                    </div>

                    <app-hint danger class="app-reranking-notification">
                        Stems need to be reranked and current stem selection will be overwritten.
                    </app-hint>
                </app-modal-content>

                <app-modal-actions>
                    <app-link @click="onCancel">Cancel</app-link>
                    <app-button colour="green" @click="onSave" :disabled="isDisabled">Save</app-button>
                </app-modal-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<style scoped>
    .app-optimisation-targets {
        display: flex;
        justify-content: space-between;
        align-items: end;
    }

    .app-optimisation-target {
        font-size: 12px;
        font-weight: 300;
    }

    .app-optimisation-target-dialog {
        background-color: var(--planner-blue-button);
        color: var(--planner-white);
    }

    .app-reranking-notification {
        margin-top: 20px;
    }
</style>
<script setup lang="ts">
    import { computed, ref } from 'vue';
    import GroupLabel from '@/planner/components/GroupLabel.vue';
    import AppModalActions from '@/components/modal/AppModalActions.vue';
    import AppButton from '@/components/AppButton.vue';
    import AppModalContent from '@/components/modal/AppModalContent.vue';
    import AppModalTitle from '@/components/modal/AppModalTitle.vue';
    import AppLink from '@/components/AppLink.vue';
    import AppHint from '@/components/AppHint.vue';
    import { usePlannerStore } from '@/planner/plannerStore';
    import { formatLength } from '@/lib/format/formatLength';
    import { assert } from '@/util';
    import type { Adjustments } from '@/planner/plannerState';
    import PlannerSlider from '@/planner/components/PlannerSlider.vue';

    const store = usePlannerStore();

    const editDisabled = computed(() => store.targetAdjustments === null);
    const isDialogOpen = ref(false);
    const selectedAdjustments = ref<Adjustments>({ legLength: 0, offset: 0 });

    function openDialog() {
        assert(store.targetAdjustments);
        selectedAdjustments.value = { ...store.targetAdjustments };
        isDialogOpen.value = true;
    }

    const isDisabled = computed(() => {
        return (
            selectedAdjustments.value.legLength === store.targetAdjustments?.legLength &&
            selectedAdjustments.value.offset === store.targetAdjustments?.offset
        );
    });

    function onCancel() {
        isDialogOpen.value = false;
    }

    async function onSave() {
        isDialogOpen.value = false;
        store.setTargetAdjustments(selectedAdjustments.value);
    }
</script>
