import { client } from '@/api/http';
import { type AxiosRequestConfig, HttpStatusCode } from 'axios';
import type { ApiCaseCatStacks } from '@/api/cat-stacks/types';

export const fetchCatStacks = async (
    caseId: number,
    config?: AxiosRequestConfig,
): Promise<ApiCaseCatStacks | null> => {
    const response = await client.get<ApiCaseCatStacks>(`/cases/${caseId}/cat-stacks`, config);
    const { status, data } = response;
    if (status === HttpStatusCode.Ok) {
        return data;
    } else if (status === HttpStatusCode.NotFound) {
        return null;
    } else {
        throw Error(`Failed to load cat-stack`);
    }
};
