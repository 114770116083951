import { all, equals } from 'ramda';
import type { TemplateUpdate } from '@/api/template/putTemplate';
import type { Matrix4 } from 'three';
import { floatsApproxEqual, arraysApproxEqual } from '@/geometry/approxEquals';
import { formatMatrixBasis, joinIndented } from '@/geometry/formatMath';
import type { Url } from '@/formus/types';

/** Options for comparing surgical template-properties */
export type TemplatePropertyOptions = {
    /** Include stem-related properties, including stem-transform. Defaults to true. */
    includeStemProperties?: boolean;

    /** Include the stem-transform property. Defaults to true */
    includeStemTransform?: boolean;
};

const defaultOptions: TemplatePropertyOptions = {
    includeStemProperties: true,
    includeStemTransform: true,
};

/**
 * Check whether values of properties in the given templates are equal
 *
 * @return true if there are any properties that are not equal.
 * */
export function templatesAreEqual(
    expected: TemplateUpdate,
    actual: TemplateUpdate,
    options?: TemplatePropertyOptions,
): boolean {
    return _templatePropertiesAreEqual(
        expected,
        actual,
        _makeTemplateProperties({ ...defaultOptions, ...options }),
    );
}

export function templateCupPropertiesAreEqual(
    expected: TemplateUpdate,
    actual: TemplateUpdate,
): boolean {
    return _templatePropertiesAreEqual(expected, actual, cupProperties);
}

export function formatTemplateCupDifferences(
    expected: TemplateUpdate,
    actual: TemplateUpdate,
): string[] {
    return _formatTemplatePropertyDifferences(expected, actual, cupProperties);
}

export function templateStemPropertiesAreEqual(
    expected: TemplateUpdate,
    actual: TemplateUpdate,
): boolean {
    return _templatePropertiesAreEqual(expected, actual, stemProperties);
}

export function formatTemplateStemDifferences(
    expected: TemplateUpdate,
    actual: TemplateUpdate,
): string[] {
    return _formatTemplatePropertyDifferences(expected, actual, stemProperties);
}

/**
 * Format each difference between properties of the given templates into a string.
 *
 * @return a string for each difference in properties.
 * */
export function formatTemplateDifferences(
    expected: TemplateUpdate,
    actual: TemplateUpdate,
    options?: TemplatePropertyOptions,
): string[] {
    return _formatTemplatePropertyDifferences(
        expected,
        actual,
        _makeTemplateProperties({ ...defaultOptions, ...options }),
    );
}

// -----------------------------------------------------------------------------------------------------

/** Tolerance for difference between floating-point components */
const TOLERANCE = 0.01;

type PropertiesAreEqual = (expected: TemplateUpdate, actual: TemplateUpdate) => boolean;

type TemplateProperty = {
    name: string;
    areEqual: PropertiesAreEqual;
    formatValue: (expected: TemplateUpdate) => string;
};

type GetValue<T> = (template: TemplateUpdate) => T | null | undefined;

function isNull<T>(value: T | null | undefined): value is null | undefined {
    return value === null || value === undefined;
}

function areEqualOrBothNull<T>(
    value1: T | null | undefined,
    value2: T | null | undefined,
    areEqual: (value1: T, value2: T) => boolean,
): boolean {
    if (isNull(value1)) {
        return isNull(value2);
    } else if (isNull(value2)) {
        return false;
    } else {
        return areEqual(value1, value2);
    }
}

function formatNullable<T>(
    value: T | null | undefined,
    format: (value: T) => string = (value) => String(value),
): string {
    if (value === null) {
        return 'null';
    } else if (value === undefined) {
        return 'undefined';
    } else {
        return format(value);
    }
}

function transformProperty(name: string, getValue: GetValue<Matrix4>): TemplateProperty {
    return {
        name,
        areEqual: (expected, actual) =>
            areEqualOrBothNull(getValue(expected), getValue(actual), (t1, t2) =>
                arraysApproxEqual(t1.elements, t2.elements),
            ),
        formatValue: (template) => {
            const value = getValue(template);
            return formatNullable(
                value,
                (matrix) => `\n${joinIndented(6)(formatMatrixBasis(matrix))}`,
            );
        },
    };
}

function numberProperty(name: string, getValue: GetValue<number>): TemplateProperty {
    return {
        name,
        areEqual: (expected, actual) =>
            areEqualOrBothNull(getValue(expected), getValue(actual), equals),
        formatValue: (template) => {
            const value = getValue(template);
            return formatNullable(value);
        },
    };
}

function floatProperty(name: string, getValue: GetValue<number>): TemplateProperty {
    return {
        name,
        areEqual: (expected, actual) =>
            areEqualOrBothNull(getValue(expected), getValue(actual), (v1, v2) =>
                floatsApproxEqual(v1, v2, TOLERANCE),
            ),
        formatValue: (template) => {
            const value = getValue(template);
            return formatNullable(value);
        },
    };
}

function urlProperty(name: string, getValue: GetValue<Url>): TemplateProperty {
    return {
        name,
        areEqual: (expected, actual) =>
            areEqualOrBothNull(getValue(expected), getValue(actual), equals),
        formatValue: (template) => {
            return formatNullable(getValue(template));
        },
    };
}

const targetLegLength = numberProperty('target-leg-length-change', (t) => t.targetLegLengthChange);
const targetOffset = numberProperty('target-offset-change', (t) => t.targetOffsetChange);
const stem = urlProperty('stem', (t) => t.stem);
const stemTransform = transformProperty('stem-transform', (t) => t.stemTransform);
const headOffset = urlProperty('head-offset', (t) => t.head);
const cup = urlProperty('cup', (t) => t.cup);
const liner = urlProperty('liner', (t) => t.liner);
const cupAnteversion = floatProperty('cup-anteversion', (t) => t.cupRotation.anteversion);
const cupInclination = floatProperty('cup-inclination', (t) => t.cupRotation.inclination);
const cupOffsetAP = floatProperty('cup-offset-ap', (t) => t.cupOffset.ap);
const cupOffsetSI = floatProperty('cup-offset-si', (t) => t.cupOffset.si);
const cupOffsetML = floatProperty('cup-offset-ml', (t) => t.cupOffset.ml);

const targetProperties = [targetLegLength, targetOffset];
const stemProperties = [stem, headOffset, stemTransform];
const cupProperties = [
    cup,
    liner,
    cupAnteversion,
    cupInclination,
    cupOffsetAP,
    cupOffsetSI,
    cupOffsetML,
];

/* Make a set of template-properties given some options */
function _makeTemplateProperties(options: TemplatePropertyOptions): TemplateProperty[] {
    const properties = [...targetProperties, ...cupProperties];
    if (options.includeStemProperties) {
        properties.push(stem, headOffset);
        if (options.includeStemTransform) {
            properties.push(stem, stemTransform);
        }
    }
    return properties;
}

function _templatePropertiesAreEqual(
    expected: TemplateUpdate,
    actual: TemplateUpdate,
    properties: TemplateProperty[],
): boolean {
    return all((p) => p.areEqual(expected, actual), properties);
}

function _formatTemplatePropertyDifferences(
    expected: TemplateUpdate,
    actual: TemplateUpdate,
    properties: TemplateProperty[],
): string[] {
    return properties.flatMap((property) => {
        if (property.areEqual(expected, actual)) {
            return [];
        } else {
            return [
                `${property.name}: `,
                `  expected: ${property.formatValue(expected)} `,
                `  actual: ${property.formatValue(actual)}`,
            ];
        }
    });
}
